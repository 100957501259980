// Color Variables
$primary-color: #222;
$primary-color-light: #4a4a4a;
$blue: #0d47a1;
$gray-100: #9e9e9e;
$border-color: #d8d8d8;
$white: #fff;

/*BORDER RADIUS*/
@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

// Font-size variables
$font-size-small: 0.75rem;
$font-size-x-regular: 0.875rem;
$font-weight-medium: 500;

.login-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    min-height: 100vh;

    .login-page-card {
        display: none;

        @media (min-width: 576px) {
            width: 400px;
        }
    }


    .input-group-text {
        background: transparent;
    }

    .form-control {
        &:focus {
            border-color: #ced4da !important;
        }
    }

    .small-text {
        font-size: $font-size-x-regular;
    }

    .login-btn-group {
        .btn-outline-secondary {
            width: 234px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            height: 44px;

            &:hover,
            &:focus {
                border-color: $blue;
                color: $blue;
                background-color: transparent;
            }
        }

        img {
            width: 22px;
            margin-right: 10px;
        }
    }

    .card-header {
        background-color: $white;
    }

    .card-footer {
        flex-direction: column;
        background-color: $white;
        width: fit-content;
        margin: auto;

        .form-check {
            width: 220px;
            margin: auto;
        }

        .form-check-label {
            font-size: $font-size-small;
            color: rgba($gray-100, 0.80);
        }

        a {
            color: rgba($gray-100, 0.80);
        }

        u {
            color: rgba($primary-color-light, 0.80) !important;
        }

        .alert {
            display: block;
            margin-top: 10px;
        }
    }

    .text-danger {
        display: block;
        width: 100%;
        margin-top: 5px;
    }

    .form-check-input:checked {
        background-color: #ffffff;
        border-color: $primary-color;
    }


    .register-text {
        margin-top: 60px;
        font-size: $font-size-x-regular;
        color: rgba($primary-color-light, 0.80);
    }

    .back-btn {
        position: absolute;
        top: 0px;
        left: 17px;

        &.back-btn-forgot {
            left: -36px;
        }
    }

    .form-container {
        width: 280px;
        margin: 0 auto;

        @media (max-width: 576px) {
            width: 100%;
            margin: 0px;
        }
    }

    .av-small {
        font-size: $font-size-x-regular !important;
    }

    .av-text-light {
        color: $border-color !important;
    }

    .meaasge-text {
        color: $primary-color-light;
        font-size: $font-size-x-regular;
        font-weight: $font-weight-medium;
        line-height: 18px;
    }
}

.form-control-lg{
    font-size: 1rem !important;
}

.google-btn{
    svg{
        display: none;
    }
}